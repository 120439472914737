import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { mobileViewBreakpoint } from "../components/helper";
import Text from "../components/Text/Text";
import Button from "../components/Button/Button";
import HeadingCTA from "../components/HeadingCTA/HeadingCTA";
import MainSlider from "../components/MainSlider/MainSlider";
import Summary from "../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& h2": {
      color: "#122044"
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 0px 28px 0px",
      margin: "60px auto 0px auto"
    }
  },

  sectionTitle: {
    margin: "80px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "40px 0px"
    }
  },
  mainContainer: {
    margin: "0px -16px"
  }
}));
const Article = () => {
  const classes = useStyles();

  return (
    <MainLayout
      title={"Event and Transportation Management and Consulting Services"}
      h1
      banner={
        <StaticImage
          src="../assets/images/events/EVENT_TRANSPORT_HEADER.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Services overview" }]}
      metaTitle={"Event Transport Management & Consulting Services"}
      metaDescription={
        "MOTUS | ONE's event transportation management & consulting services are committed to delivering best-in-class service at the most economical rates."
      }
    >
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <Text
            isFlipped={true}
            /* 
          subheader="How can we help?"
          header={
            <>
              Contact us to find out how events transport can fulfill your
              needs.{" "}
            </>
          }
          button={
            <a
              href="https://app.motusone.com/login"
              target="_blank"
              rel="noopener"
            >
              <Button className={classes.loginButton}>Login</Button>
            </a>
          }*/
            text={
              <>
                {" "}
                <p>
                  <Typography component={"h2"} customVariant={"h3Bold"}>
                    You can rely on Motus | ONE to deliver world-class
                    event-transport management and consultancy. We offer a
                    comprehensive suite of services, all tailored to your exact
                    needs.
                  </Typography>
                </p>
                <p>
                  <Typography component="p" customVariant={"h5Regular"}>
                    We understand the complexity of delivering event transport
                    and logistics. Our role is to make the complex simple and
                    enable you to free up your people, managers, and resources.
                  </Typography>{" "}
                </p>
              </>
            }
          />

          <Text
            isFlipped={false}
            header={<>Everything you need, from one provider</>}
            text={
              <>
                Partner with us to take full advantage of using a sole provider.
                This arrangement reduces your administrative burden
                significantly. It also allows for a lower cost base thanks to
                our advantageous relations with a wide network of third-party
                suppliers.
              </>
            }
          />

          <Text
            isFlipped={false}
            header={<>Use our experience to your advantage</>}
            text={
              <>
                Partner with us to take full advantage of using a sole provider.
                This arrangement reduces your administrative burden
                significantly. It also allows for a lower cost base thanks to
                our advantageous relations with a wide network of third-party
                suppliers.
              </>
            }
          />

          <Text
            isFlipped={false}
            header={<>Safe and punctual guest transport</>}
            text={
              <>
                Partner with us to take full advantage of using a sole provider.
                This arrangement reduces your administrative burden
                significantly. It also allows for a lower cost base thanks to
                our advantageous relations with a wide network of third-party
                suppliers.
              </>
            }
          />
        </div>

        <div className={classes.sectionTitle}>
          <HeadingCTA title={"Core services you can depend on"} center />
        </div>

        <Summary
          category={"Transportation"}
          title={"We keep you moving"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/home/corporate-transportation-2.jpg"
              alt="corporate transportation"
            />
          }
          link={"/transportation-management-service/"}
          description={
            <>
              We consult, plan, and execute the event and corporate transport
              solutions. No matter the scale and nature of your requirements,
              the buck stops with us. Shuttle service, Staff transport, or Fleet
              procurement, every solution is tailored uniquely to your needs and
              wants.
            </>
          }
        />

        <Summary
          category={"Event Transportation"}
          title={"We make the incredibly complex, simple"}
          fullHeight={true}
          isFlipped={false}
          image={
            <StaticImage
              src="../assets/images/home/eventtransport10.jpg"
              alt="world class event transportation"
            />
          }
          link={"/event-transportation-service/"}
          description={
            <>
              Large-scale events are complex undertakings. While we combine
              traditional methods with technology that enables accurate
              reconciliation, reporting, and real-time dispatch tracking. Our
              people pledge to make every single guest experience safe,
              pleasant, and worth coming back for.
            </>
          }
        />

        <Summary
          category={"Logistics"}
          title={"We do the heavy lifting"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/home/transportation-logistics.jpeg"
              alt="transportation logistics"
            />
          }
          link={"/event-logistics-service/"}
          description={
            <>
              Use our team to simplify everything – from vehicle, plant, and
              site management to specifying and building one-off stages and
              venues.
              <ul>
                <li>
                  End-to-end solutions – from strategic planning to on-site
                  operations
                </li>

                <li>
                  Coordination of deliveries, parking, loading, and storage
                </li>

                <li>
                  Site-specific and project-wide safety management, canteens,
                  and office provision
                </li>
              </ul>
            </>
          }
        />

        <Summary
          category={"Additional Transport, Logistics & Planning services"}
          title={"Could we do more for you?"}
          fullHeight={true}
          isFlipped={false}
          image={
            <StaticImage
              src="../assets/images/planning.jpg"
              draggable="false"
            />
          }
          description={
            <>
              <Typography component={"h3"} customVariant={"bodyLargeBold"}>
                Commercial and sales advice
              </Typography>
              <p>
                If you’re bidding for a project of any size, we can help you
                build a winning proposal. Our consultants will help ensure that
                your submission satisfies the highest levels of scrutiny and
                evaluation, both for commercial and civilian projects.
              </p>

              <Typography component={"h3"} customVariant={"bodyLargeBold"}>
                Transport consultancy
              </Typography>
              <p>
                We engage at every stage of transport planning and design. Your
                project can benefit from our strengths in capacity analysis,
                traffic management, and intelligent transport systems. And at
                ground level, we incorporate the best in wayfinding systems and
                signage design.
              </p>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
